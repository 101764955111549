<template>
  <b-tr
    :class="selectedId === coupon._id ? 'active' : ''"
  >
    <b-td>
      <router-link
          :style="`${selectedId === coupon._id ? 'font-weight: bold;': ''}`"
          :to="link"
      >
        {{ coupon.title }}
      </router-link>
    </b-td>
    <b-td>
      {{getStatus}}
    </b-td>
    <b-td>
      {{coupon.period.start}} ~ {{coupon.period.end}}
    </b-td>
    <b-td>
      {{coupon.duplication ? '사용' : '미사용'}}
    </b-td>
    <b-td>
      {{coupon.limit.use ? `사용 (최대 ${coupon.limit.count}개)` : '미사용'}}
    </b-td>
  </b-tr>
</template>

<script>
export default {
  name: "list-row",
  props: {
    coupon: {
      _id: String,
      title: String,
      status: String,
      period: {
        start: String,
        end: String
      },
      duplication: Boolean,
      limit: {
        use: Boolean,
        count: Number
      }
    },
    selectedId: String,
    page: Number,
    filterData: {
      searchFilter: String,
      searchValue: String,
      searchStatus: String,
      perPage: Number
    }
  },

  computed: {
    getStatus() {
      if (this.coupon.status === 'before') return '생성 전';
      if (this.coupon.status === 'create') return '생성 완료';
      if (this.coupon.status === 'deploy') return '배포 완료';
      if (this.coupon.status === 'end') return '종료';
      if (this.coupon.status === 'expired') return '기간 지남';
      return null;
    },

    link() {
      const link = [];
      if (this.page) link.push(`page=${this.page}`);
      if (this.filterData) {
        const linkData = Object.entries(this.filterData)
            .filter(([k, v]) => v)
            .map(([k, v]) => `${k}=${v}`);
        link.push(...linkData);
      }
      return `/coupon/${this.coupon._id}` + (link.length ? `?${link.join("&")}` : "");
    },
  }
}
</script>

<style scoped>

</style>
