<template>
  <b-modal
      id="createCoupon"
      title="쿠폰 생성하기"
      no-close-on-backdrop
      no-close-on-esc
      @close="modalClose"
  >
    <div class="modal-body pb-0">
      <div>
        <b-form-group
            label="생성할 쿠폰 제목을 입력해 주세요."
            class="mb-0"
            label-size="sm"
        >
          <b-form-input
              v-model="coupon.title"
          ></b-form-input>
        </b-form-group>
      </div>
      <div>
        <div class="mt-2 mb-4">
          <v-date-picker v-model="coupon.period" is-range
                         :model-config="modelConfig"
                         :masks="masks"
                         is-dark
                         color="red">
            <template v-slot="{ inputValue, inputEvents }">
              <b-form-group
                  label="쿠폰 설정 기간"
                  description="쿠폰 설정 기간을 선택하여 주세요"
                  label-size="sm"
              >
                <div class="form-inline form-search">
                  <div class="input-group input-date">
                    <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="" readonly
                           style="background: rgba(0,0,0,.1); width: 150px;"
                           :value="inputValue.start"
                           v-on="inputEvents.start">
                  </div>
                  <div class="date-to"> ~ </div>
                  <div class="input-group input-date mr-4">
                    <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="" readonly
                           style="background: rgba(0,0,0,.1); width: 150px;"
                           :value="inputValue.end"
                           v-on="inputEvents.end">
                  </div>
                </div>
              </b-form-group>
            </template>
          </v-date-picker>
        </div>

        <div>
          <b-form-group
              label="쿠폰 중복 사용 여부"
              v-slot="{ ariaDescribedby }"
              class="col-form-label-sm"
          >
            <b-form-checkbox v-model="coupon.duplication" name="check-button" switch :aria-describedby="ariaDescribedby">
              ({{ coupon.duplication ? '사용' : '미사용' }})
            </b-form-checkbox>
          </b-form-group>
        </div>
        <div>
          <b-form-group
              label="쿠폰 최대 사용 여부"
              v-slot="{ ariaDescribedby2 }"
              class="col-form-label-sm"
          >
            <b-form-checkbox v-model="coupon.limit.use" name="check-button" switch :aria-describedby="ariaDescribedby2" class="align-middle" @change="setSettingLimit">
              <div class="d-flex justify-content-start">
                <div>({{ coupon.limit.use ? '사용' : '미사용' }})</div>
                <div class="ml-3">
                  <b-input v-if="coupon.limit.use" v-model="coupon.limit.count" type="number"></b-input>
                </div>
              </div>
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="save"
      >
        생성
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "createCoupon",
  data: () => ({
    coupon: {
      title: null,
      period: {
        start: null,
        end: null
      },
      duplication: false,
      limit: {
        use: false,
        count: 0
      }
    },
    modelConfig: {
      type: 'string',
      mask: 'YYYY-MM-DD', // Uses 'iso' if missing
    },
    masks: {
      input: 'YYYY-MM-DD',
    }
  }),
  methods: {
    setSettingLimit () {
      if(!this.coupon.limit.use) {
        this.coupon.limit.count = 0;
      }
    },

    modalClose() {
      this.$bvModal.hide("createCoupon");
      this.reset();
    },

    reset() {
      this.coupon = {
        title: null,
        period: {
          start: null,
          end: null
        },
        duplication: false,
        limit: {
          use: false,
          count: 0
        }
      }
    },

    async save() {
      if (this.coupon.title === null || this.coupon.title.trim() === '') {
        return this.$bvToast.toast(`제목을 입력해 주세요.`, {
          title: 'ERROR',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: false,
        });
      }

      if(this.coupon.period.start === null || this.coupon.period.end === null) {
        return this.$bvToast.toast(`쿠폰 설정 기간을 선택해 주세요.`, {
          title: 'ERROR',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: false,
        });
      }

      if(this.coupon.limit.use && this.coupon.limit.count < 1) {
        return this.$bvToast.toast(`쿠폰 최대 사용 수를 1개 이상 입력하여 주세요.`, {
          title: 'ERROR',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: false,
        });
      }

      const result = await this.$bvModal.msgBoxConfirm('쿠폰 그룹을 생성하시겠습니까?', {
        title: '쿠폰 그룹 생성',
        cancelTitle: '취소',
        okTitle: '확인',
        /*okVariant: 'dark',
        cancelVariant: 'outline-success',*/
        centered: true,
        footerClass: 'justify-content-center',
        bodyClass: 'pt-5 pb-5 text-center',
      });
      if(!result) return;

      try {
        const {data} = await this.axios({
          method: 'POST',
          url: '/admin/coupon-new',
          data: {
            coupon: this.coupon
          }
        });
        const {result, projectId} = data;
        if (result) {
          this.modalClose();
          this.$emit("load", projectId);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
