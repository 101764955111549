<template>
  <main class="bg-light">
    <div>
      <keep-alive>
        <top-nav/>
      </keep-alive>
    </div>
    <div>
      <router-view @listLoad="load"/>
    </div>
    <b-container class="mt-4">
      <b-row>
        <b-col v-if="pending">
        </b-col>
        <b-col v-else>
          <b-table-simple small responsive="" bordered class="bg-white">
            <b-thead>
              <b-tr>
                <b-th colspan="7">
                  <div class="d-flex">
                    <div class="p-2">
                      <div class="d-flex align-items-start mt-1">
                        <b-button
                            variant="success"
                            size="sm"
                            squared
                            @click="createProject"
                        >
                          <font-awesome-icon icon="plus"/> 쿠폰 등록
                        </b-button>
                      </div>
                    </div>
                    <filter-component
                        class="mt-2"
                        :filterData="filter"
                        :selectedId="selectedId"
                        :statusFilters="status"
                        v-on:search="search"
                    />
                  </div>
                </b-th>
              </b-tr>
              <b-tr>
                <b-th>제목</b-th>
                <b-th>쿠폰 상태</b-th>
                <b-th>쿠폰 기간</b-th>
                <b-th>쿠폰 중복</b-th>
                <b-th>쿠폰 제한</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="list.length > 0">
              <list-row
                  v-for="coupon of list"
                  :key="`event-${coupon._id}`"
                  :coupon="coupon"
                  :selected-id="selectedId"
                  :filter-data="filter"
                  :page="page"
              />
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td colspan="7">검색하신 쿠폰 프로젝트가 존재하지 않습니다.</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination-nav
              v-if="list.length"
              :link-gen="linkGen"
              v-model="page"
              :number-of-pages="perPage"
              use-router
              aria-controls="my-table"
              first-number
              last-number
              first-text="«"
              prev-text="‹"
              next-text="›"
              last-text="»"
              align="right"
              size="sm"
          />
        </b-col>
      </b-row>
    </b-container>
    <create-coupon @load="createLoad"></create-coupon>
  </main>
</template>

<script>

import filterComponent from '@/components/filter'
import CreateCoupon from "@/components/coupon-new/createCoupon.vue";
import listRow from "@/pages/coupon-new/list-row.vue";
export default {
  name: "coupon-new",
  components: {
    CreateCoupon,
    filterComponent,
    listRow
  },
  data: () => ({
    pending: false,
    page: 1,
    total: 0,
    perPage: 0,
    list: [],
    selectedId: null,
    /*filters: [
      {text: '선택', value: null},
      {text: '코드', value: 'code'}
    ],*/
    status: [
      {text: '상태', value: null},
      {text: '생성 전', value: 'before'},
      {text: '생성 완료', value: 'create'},
      {text: '배포 완료', value: 'deploy'},
      {text: '종료', value: 'end'},
      /*{text: '기간 지남', value: 'expired'},*/
    ],
    filter: {
      searchFilter: null,
      searchValue: null,
      searchStatus: null,
      perPage: 10,
    },
    setting: {
      use: false,
      date: {
        start: null,
        end: null
      },
      duplication: false,
      limit: {
        use: false,
        count: 0
      }
    },
    modelConfig: {
      type: 'string',
      mask: 'YYYY-MM-DD', // Uses 'iso' if missing
    },
    masks: {
      input: 'YYYY-MM-DD',
    }
  }),
  created() {
    const {_id} = this.$route.params
    if (_id) this.selectedId = _id
    const { page, searchFilter, searchValue, searchStatus, perPage } = this.$route.query
    if (page && !isNaN(+page)) this.page = +page
    /*if (searchFilter) this.filter.searchFilter = searchFilter
    if (searchValue) this.filter.searchValue = searchValue*/
    if (searchStatus) this.filter.searchStatus = searchStatus
    if (perPage && !isNaN(+perPage)) this.filter.perPage = +perPage
    this.load(this.page)
  },
  methods: {
    async load (pageNo) {
      this.pending = true;
      try {
        this.page = pageNo ? isNaN(+pageNo) ? 1 : +pageNo : 1
        const params = {
          page: this.page,
          ...this.filter
        }

        const {data} = await this.axios({
          url: `/admin/coupon-new`,
          params
        })

        const {result, list, page, total, totalPage} = data

        if (result) {
          this.list = list
          this.page = page
          this.total = total
          this.perPage = totalPage
        }
      } catch (e){}
      this.pending = false;
    },

    linkGen (page) {
      return `${this.$route.path}?page=${page}&${Object.entries(this.filter).map(v => v[1] ? `${v[0]}=${v[1]}` : `${v[0]}`).join('&')}`
    },

    createProject() {
      this.$bvModal.show('createCoupon');
    },

    getStatus(status) {
      if (status === 'before') return '생성 전';
      if (status === 'create') return '생성 완료';
      if (status === 'deploy') return '배포 완료';
      if (status === 'end') return '종료';
      if (status === 'expired') return '기간 지남';
      return null;
    },

    async createLoad(projectId) {
      await this.$router.push(`/coupon/${projectId}`);
    }
  }
}
</script>

<style scoped>

</style>
